<template>
  <PageDetailLazyLoad v-if="isLoading" :onBack="goBack" />
  <div v-else class="items-center">
    <div
      class="flex flex-row justify-between items-center border-b border-gray-lp-400"
    >
      <div class="flex flex-row my-4">
        <img
          src="@/app/ui/assets/svg/arrow-left.svg"
          @click="goBack"
          class="w-5 mr-3 cursor-pointer"
          alt="back"
        />
        <div class="font-semibold text-20px mr-4 text-black-lp-200">
          Edit Lokasi
        </div>
      </div>
      <div v-show="!isError" class="inline-flex">
        <button-fill
          :isDisabled="!ursaCode"
          title="Simpan"
          @click="onOpenSave"
          class="px-8 bg-red-lp-300"
        />
      </div>
    </div>
    <ErrorMessage
      v-if="isError"
      :errorType="errorCause"
      :onTryAgain="fetchDistrictData"
    />
    <div v-else class="flex flex-row py-4">
      <div
        class="w-2/3 px-4 text-12px xxl:text-14px overflow-auto h-screen pb-32"
      >
        <div class="flex flex-row w-3/4">
          <div class="py-3 w-1/2 mr-4">
            <div class="font-semibold text-black-lp-300">Ursa Code</div>
            <div class="inline-block relative z-10 w-full mr-4">
              <input
                v-model="ursaCode"
                @input="e => inputUrsaCode(e.target.value)"
                placeholder="REM"
                :class="
                  `block appearance-none uppercase py-1.5 xxl:py-2 px-4 rounded border focus:outline-none focus:border-red-lp-100 focus:shadow-input-active my-1 text-black-lp-300 w-full ${isDisabled &&
                    'bg-white'} ${
                    code === 200 ? 'border-red-lp-800' : 'border-gray-lp-400'
                  }`
                "
                :maxlength="7"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-lp-500 mr-2 text-12px"
                @click="onClear"
              >
                <img
                  v-if="code === 200"
                  src="@/app/ui/assets/svg/exclamation-circle-solid.svg"
                  class="w-5"
                  alt="warning"
                />
                <span v-else>{{ ursaCode.length }}/7</span>
              </div>
            </div>
            <p
              v-show="code === 200"
              class="text-12px xxl:text-14px text-red-lp-700 mt-2"
            >
              Ursa code sudah digunakan
            </p>
          </div>
          <div class="py-3 w-1/2">
            <div class="font-semibold text-black-lp-300">District Name</div>
            <input
              type="text"
              placeholder="Rancasari, Bandung"
              :class="
                `py-1.5 xxl:py-2 px-4 opacity-50 rounded border border-gray-lp-400 focus:outline-none focus:shadow my-1 text-black-lp-300 w-full ${isDisabled &&
                  'bg-white'}`
              "
              :disabled="isDisabled"
              :value="dataDetail().name"
            />
          </div>
        </div>
        <div class="flex flex-row w-3/4">
          <div class="py-3 w-1/2 mr-4">
            <div class="font-semibold text-black-lp-300">Kode Pos Asal</div>
            <input
              type="text"
              placeholder="56564"
              :class="
                `py-1.5 xxl:py-2 px-4 opacity-50 rounded border border-gray-lp-400 focus:outline-none focus:shadow my-1 text-black-lp-300 w-full ${isDisabled &&
                  'bg-white'}`
              "
              :disabled="isDisabled"
              :value="dataDetail().originZipCode"
            />
          </div>
          <div class="py-3 w-1/2">
            <div class="font-semibold text-black-lp-300">Kode Pos</div>
            <input
              type="text"
              placeholder="87687"
              :class="
                `py-1.5 xxl:py-2 px-4 opacity-50 rounded border border-gray-lp-400 focus:outline-none focus:shadow my-1 text-black-lp-300 w-full ${isDisabled &&
                  'bg-white'}`
              "
              :disabled="isDisabled"
              :value="dataDetail().destinationZipCode"
            />
          </div>
        </div>
        <div class="flex flex-row w-3/4">
          <div class="py-3 w-1/2 mr-4">
            <div class="font-semibold text-black-lp-300">City</div>
            <input
              type="text"
              placeholder="Bandung"
              :class="
                `py-1.5 xxl:py-2 px-4 opacity-50 rounded border border-gray-lp-400 focus:outline-none focus:shadow my-1 text-black-lp-300 w-full ${isDisabled &&
                  'bg-white'}`
              "
              :disabled="isDisabled"
              :value="dataDetail().cityName"
            />
          </div>
          <div class="py-3 w-1/2">
            <div class="font-semibold text-black-lp-300">City Code</div>
            <input
              type="text"
              placeholder="BDD"
              :class="
                `py-1.5 xxl:py-2 px-4 opacity-50 rounded border border-gray-lp-400 focus:outline-none focus:shadow my-1 text-black-lp-300 w-full ${isDisabled &&
                  'bg-white'}`
              "
              :disabled="isDisabled"
              :value="dataDetail().cityCode"
            />
          </div>
        </div>
        <div class="flex flex-row w-3/4">
          <div class="py-3 w-1/2 mr-4">
            <div class="font-semibold text-black-lp-300">Type</div>
            <div class="mr-2">
              <Select
                :dataSelect="typeData"
                :onOpenSelect="onOpenTypeSelect"
                :onCloseSelect="onCloseTypeSelect"
                :onChooseSelect="onSelectType"
                :value="type"
                :isOpenMenu="typeSelect"
                class="w-full my-1"
              />
            </div>
          </div>
          <div class="py-3 w-1/2">
            <div class="font-semibold text-black-lp-300">Vendor Code</div>
            <input
              type="text"
              placeholder="TIKI, JNE etc"
              :class="
                `py-1.5 xxl:py-2 px-4 opacity-50 rounded border border-gray-lp-400 focus:outline-none focus:shadow my-1 text-black-lp-300 w-full ${isDisabled &&
                  'bg-white'}`
              "
              :disabled="isDisabled"
              :value="dataDetail().vendorCode"
            />
          </div>
        </div>
        <div
          class="flex flex-col text-12px border-t border-gray-lp-400 my-6 py-4"
        >
          <div class="py-3">
            <div class="font-semibold text-black-lp-300">COD</div>
            <div class="my-1 text-gray-lp-500 max-w-md">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            </div>
          </div>
          <div
            class="flex flex-row text-12px xxl:text-14px items-center text-black-lp-300"
          >
            <div
              @click="() => changeCod()"
              :class="
                `toggle-switch inline-block cursor-pointer ${
                  isCod() ? 'toggle-active bg-red-lp-100' : 'bg-gray-lp-200'
                }`
              "
            >
              <div class="toggle-knob bg-white inline-block"></div>
            </div>
            <div class="ml-4">
              {{ isCod() ? "Aktif" : "Tidak Aktif" }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-col text-12px border-t border-gray-lp-400 my-6 py-4"
        >
          <div class="py-3">
            <div class="font-semibold text-black-lp-300">Status Rute</div>
            <div class="my-1 text-gray-lp-500 max-w-md">
              Rute dengan status yang aktif akan digunakan sebagai lokasi awal
              atau destinasi pada modul route mapping
            </div>
          </div>
          <div
            class="flex flex-row text-12px xxl:text-14px items-center text-black-lp-300"
          >
            <div
              @click="() => onOpenRoute()"
              :class="
                `toggle-switch inline-block cursor-pointer ${
                  isRoute ? 'toggle-active bg-red-lp-100' : 'bg-gray-lp-200'
                }`
              "
            >
              <div class="toggle-knob bg-white inline-block"></div>
            </div>
            <div class="ml-4">Rute {{ isRoute ? "Aktif" : "Tidak Aktif" }}</div>
          </div>
        </div>
      </div>
      <div
        class="w-1/3 px-8 text-12px xxl:text-14px border-l border-gray-lp-400"
      >
        <div class="py-3">
          <div class="font-semibold text-black-lp-300">Status Rute</div>
          <div class="my-1 text-black-lp-300">
            <div class="flex">
              <div
                :class="
                  `rounded-full px-2 py-0 font-medium ${
                    isRoute ? 'bg-green-lp-300' : 'bg-red-lp-600'
                  } ${isRoute ? 'text-green-lp-200' : 'text-red-lp-500'}`
                "
              >
                {{ isRoute ? "Active" : "Inactive" }}
              </div>
            </div>
          </div>
        </div>
        <div class="py-3">
          <div class="font-semibold text-black-lp-300">Tanggal Dibuat</div>
          <div class="my-1 text-black-lp-300">{{ createdAt }}</div>
          <div class="text-gray-lp-500">{{ createdBy }}</div>
        </div>
        <div class="py-3">
          <div class="font-semibold text-black-lp-300">Terakhir Diubah</div>
          <div class="my-1 text-black-lp-300">{{ updateAt }}</div>
          <div class="text-gray-lp-500">{{ updateBy }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Route -->
  <Modal
    v-if="openRoute"
    :onClose="onCloseRoute"
    :onSubmit="changeRoute"
    :title="isRoute ? 'Non-aktifkan Lokasi' : 'Aktifkan Lokasi'"
    :message="
      isRoute
        ? 'Selama lokasi berstatus non-aktif maka lokasi tidak dapat digunakan dalam “route mapping”'
        : 'Selama lokasi berstatus aktif maka lokasi dapat digunakan dalam “route mapping”'
    "
    image="inactive-location"
    :textSuccess="isRoute ? 'Non-aktifkan' : 'Aktifkan'"
    textCancel="Kembali"
    class="px-8"
  />

  <!-- Modal Success -->
  <Modal
    v-show="openSuccess"
    :onSubmit="onCloseSuccess"
    title="Perubahan Disimpan"
    :message="`Lokasi ${dataDetail().name} berhasil diubah`"
    image="image-modal-success"
    textSuccess="Oke"
    class="px-8"
  />

  <!-- Close when changed -->
  <Modal
    v-show="isEditTerminated"
    :onSubmit="handlerClose"
    :onClose="handlerCancelClose"
    title="Yakin Keluar Halaman ?"
    message="Anda belum selesai mengisi form. Permintaan tidak akan diproses jika Anda keluar dari halaman ini."
    image="are-you-sure"
    textSuccess="Keluar"
    textCancel="Kembali"
    class="px-8"
  />

  <!-- Save data -->
  <Modal
    v-show="openSave()"
    :onSubmit="_onEdit"
    :onClose="onCloseSave"
    title="Konfirmasi Perubahan Data Lokasi"
    :message="
      `Anda yakin akan menyimpan perubahan untuk  lokasi “${
        dataDetail().name
      } - ${dataDetail().code}”`
    "
    image="are-you-sure"
    textSuccess="Simpan"
    textCancel="Kembali"
    class="px-8"
    :isLoading="isLoading"
  />
</template>

<script>
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { formatDate } from "@/app/infrastructures/misc/Utils";

import router from "@/app/ui/router";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isError) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        this.setCode();
        next();
      } else {
        next(false);
      }
    } else {
      this.setCode();
      LocationController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  async created() {
    this.getDetailDistrictData(this.$route.params.id);
  }

  get id() {
    return this.$route.params.id;
  }

  goBack() {
    this.$router.push(`/network/location/district/${this.id}`);
  }

  fetchDistrictData() {
    this.getDetailDistrictData(this.$route.params.id);
  }

  // Set data
  get code() {
    return LocationController.code;
  }

  setUrsaCode = debounce(value => {
    LocationController._onGetUrsaCode({ value: value.toUpperCase() });
  }, 1000);

  isDisabled = true;

  // Type
  typeData = [
    { name: "WITHIN CITY", value: "within-city" },
    { name: "FORWARD AREA", value: "forward-area" },
    { name: "PT POS", value: "pt-pos" },
    { name: "PT POS LANJUTAN", value: "pt-pos-lanjutan" }
  ];
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  onSelectType(name, value) {
    this.onCloseTypeSelect();
    this.setType(value);
  }

  // Detail data
  getDetailDistrictData(id) {
    return LocationController.getDetailDistrict({ id });
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  dataDetail() {
    const detail = LocationController.districtDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updateAt === "0001-01-01T00:00:00Z"
        ? formatDate(detail.createdAt)
        : formatDate(detail.updateAt);
    this.updateBy = detail.updatedBy;
    return detail;
  }

  get ursaCode() {
    return LocationController.ursaCode;
  }

  get cacheUrsaCode() {
    return LocationController.cacheUrsaCode;
  }

  inputUrsaCode(value) {
    LocationController.setUrsaCode(value);
  }

  setCode() {
    LocationController.setCode(0);
  }

  get type() {
    return LocationController.type;
  }

  setType(value) {
    return LocationController.setType(value);
  }

  get cacheType() {
    return LocationController.cacheType;
  }

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    if (this.isRoute) {
      this.openRoute = true;
    } else {
      LocationController.setRoute(!this.isRoute);
    }
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  get isRoute() {
    return LocationController.isRoute;
  }
  changeRoute() {
    LocationController.setRoute(!this.isRoute);
    this.openRoute = false;
    this.messageSuccess = `Lokasi “${this.dataDetail().name} - ${
      this.dataDetail().code
    }”`;
  }
  cacheRoute() {
    return LocationController.cacheRoute;
  }
  routeForComparison() {
    return this.isRoute;
  }

  // Toggle Free Trade Zone
  isCod() {
    return LocationController.isCod;
  }
  changeCod() {
    LocationController.setCod(!this.isCod());
  }
  cacheCod() {
    return LocationController.cacheCod;
  }
  codForComparison() {
    return this.isCod();
  }

  // Modal Success
  messageSuccess = "";
  get openSuccess() {
    return LocationController.openModalSuccess;
  }
  onCloseSuccess() {
    router.push(`/network/location/district/${this.$route.params.id}`);
  }

  // Save data
  openSave() {
    return LocationController.openModal;
  }
  onOpenSave() {
    LocationController.setOpenModal(true);
  }
  onCloseSave() {
    LocationController.setOpenModal(false);
  }

  _onEdit() {
    LocationController._onEditDistrict({
      id: this.id,
      type: this.type,
      status: this.isRoute ? "active" : "inactive",
      ursaCode: this.ursaCode,
      isCod: this.isCod() ? "yes" : "no"
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return (
      this.cacheRoute() !== this.routeForComparison() ||
      this.cacheCod() !== this.codForComparison() ||
      this.ursaCode !== this.cacheUrsaCode ||
      this.type !== this.cacheType
    );
  }

  onOpenClosePage(to) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  //Error
  get isError() {
    return LocationController.isError;
  }

  //Error Cause
  get errorCause() {
    return LocationController.isErrorCauseDistrict;
  }

  handleErrorClose() {
    LocationController.handleError();
    this.getDetailDistrictData(this.$route.params.id);
  }

  //Loading
  get isLoading() {
    return LocationController.isLoadingDetail;
  }
}
</script>

<style lang="postcss">
/* Toggle */
.toggle-switch,
.toggle-switch .toggle-knob {
  -moz-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.toggle-switch {
  height: 30px;
  width: 55px;
  margin: 2px;
  margin-bottom: 5px;
  border-radius: 30px;
  border: solid 1px #d2d6de;
  box-shadow: inset 1px 1px 9px -3px rgba(4, 4, 4, 0.08),
    1px 2px 6px -2px rgba(0, 0, 0, 0.01);
}

.toggle-switch .toggle-knob {
  width: 28px;
  height: 26px;
  border: solid 1px rgba(126, 126, 126, 0.07);
  box-shadow: 0 1px 3px rgba(107, 106, 106, 0.26),
    0 5px 1px rgba(107, 106, 106, 0.13);
  border-radius: 26px;
  margin: 1px 1px;
}

.toggle-switch.toggle-active {
  border: solid 1px transparent;
}

.toggle-switch.toggle-active .toggle-knob {
  margin-left: 24px;
}
</style>
